<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-04-30 10:46:40
 * @LastEditors: 支磊
 * @LastEditTime: 2021-10-22 15:01:25
-->
<template>
  <div class="IndexMainComponents">
    <div class="center">
      <!-- 设备概况 -->
      <div class="EquipmentStatus">
        <div class="title">设备概况</div>
        <div class="content">
          <div class="row1">
            <div>
              <img src="@/assets/images/shebeiyunxingicon.png" alt="" />
            </div>
            <div>
              <div class="number">
                <span>{{ infoData.deviceRun }}</span>
                <span>/{{ infoData.deviceRunAll }}</span>
              </div>
              <div class="gary font14 weight500">设备运行</div>
            </div>
          </div>
          <div class="row2">
            <div>
              <img src="@/assets/images/cedianzaixianicon.png" alt="" />
            </div>
            <div>
              <div class="number">
                <span>{{ infoData.pointOnline }}</span>
                <span>/{{ infoData.pointOnlineAll }}</span>
              </div>
              <div class="gary font14 weight500">测点在线</div>
            </div>
          </div>
        </div>
      </div>
      <div class="baojingqingkuangchuli">
        <div class="title">报警情况处理</div>
        <div class="content">
          <div class="row1">
            <div>
              <img src="@/assets/images/jinrishangbaoicon.png" alt="" />
              <div class="info">
                <div>今日上报</div>
                <div>{{ infoData.reportToday }}</div>
              </div>
            </div>
            <div>
              <img src="@/assets/images/daichuliicon.png" alt="" />
              <div class="info">
                <div>待处理</div>
                <div>{{ infoData.pending }}</div>
              </div>
            </div>
          </div>

          <div class="row2">
            <div>
              <img src="@/assets/images/jinriwanchengicon.png" alt="" />
              <div class="info">
                <div>今日完成</div>
                <div>{{ infoData.completedToday }}</div>
              </div>
            </div>
            <div>
              <img src="@/assets/images/yichuliicon.png" alt="" />
              <div class="info">
                <div>已处理</div>
                <div>{{ infoData.processed }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shebeishishizhuangtai">
        <div class="title">设备实时状态</div>
        <div class="content">
          <statusEchart :infoData="infoData"></statusEchart>
        </div>
      </div>
      <div class="baojinglishitongji">
        <div class="title">报警历史统计</div>
        <div class="content">
          <div class="row1">
            <div>
              <img src="@/assets/images/gaogaobao.png" alt="" />
              <div class="info">高高报</div>
            </div>
            <div>
              <img src="@/assets/images/gaobao.png" alt="" />
              <div class="info">高报</div>
            </div>
            <div>
              <img src="@/assets/images/dibao.png" alt="" />
              <div class="info">低报</div>
            </div>
          </div>
          <div class="row2">
            <div class="gaogaobao">{{ infoData.highHighReport }}</div>
            <div class="gaobao">{{ infoData.highReport }}</div>
            <div class="dibao">{{ infoData.lowReport }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import statusEchart from "./statusEchart.vue";
export default {
  name: "IndexMainComponents",
  components: {
    statusEchart,
  },
  props: {
    infoData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.gary {
  color: #6c7380;
}
.weight500 {
  font-weight: 500;
}
.font14 {
  font-size: 14px;
}
.flex_box {
  display: flex;
  align-content: center;
}
.IndexMainComponents {
  padding: 0 10px;
  text-align: left;
  .center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    width: 1300px;
    margin: auto;

    > div {
      margin: 20px;
      padding: 20px;
    }
    .title {
      font-size: 14px;
      color: #333333;
    }
  }

  .EquipmentStatus {
    width: 590px;
    height: 334px;
    background: #ffffff;
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: calc(100% - 20px);
    }
    .row1,
    .row2 {
      width: 310px;

      > div:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > div:nth-child(2) {
        margin-top: 20px;
        text-align: center;
      }
      .number {
        min-width: 58px;
        margin-bottom: 10px;
        span:first-child {
          font-size: 30px;
          font-weight: 700;
        }
        span:nth-child(2) {
          margin-left: 5px;
          font-size: 14px;
          color: #8f959f;
        }
      }
    }
    .row1 {
      border-right: 1px solid #f0f0f0;
    }
  }
  .baojingqingkuangchuli {
    width: 590px;
    height: 334px;
    background: #ffffff;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: calc(100% - 20px);
    }
    .row1,
    .row2 {
      display: flex;
      justify-content: space-between;
      width: 330px;
      margin: auto;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .info {
        margin-left: 20px;
        font-size: 14px;
        div:first-child {
          color: #6c7380;
        }
        div:nth-child(2) {
          margin-top: 10px;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #131313;
        }
      }
    }
    .row1 {
    }
    .row2 {
      margin-top: 1px;
    }
  }
  .shebeishishizhuangtai {
    width: 590px;
    height: 334px;
    background: #ffffff;
    .content {
      height: calc(100% - 20px);
    }
  }
  .baojinglishitongji {
    width: 590px;
    height: 334px;
    background: #ffffff;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: calc(100% - 20px);
    }
    .row1,
    .row2 {
      display: flex;
      justify-content: space-between;
      width: 310px;
      margin: auto;
      > div {
        width: 33%;
        text-align: center;
      }
      .gaogaobao {
        color: #fe656a;
      }
      .gaobao {
        color: #f76e1b;
      }
      .dibao {
        color: #ffc803;
      }
    }
    .row1 {
      margin-bottom: 10px;
      img {
        width: 36px;
        height: 36px;
      }
      .info {
        margin-top: 10px;
        font-size: 14px;
      }
    }
    .row2 {
      margin-top: 1px;
      > div {
        margin-top: 10px;
        font-size: 36px;
        font-weight: bold;
      }
    }
  }
}
</style>
