<!--
 * @Description: 设备实时状态
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-08 10:40:09
 * @LastEditors: 支磊
 * @LastEditTime: 2021-11-22 18:22:15
-->
<template>
  <div class="EchartAssetRealtimeStat">
    <div id="statusEchart" ref="EchartAssetRealtimeStat"></div>
  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  name: "EchartAssetRealtimeStat",
  components: {},
  data() {
    return {};
  },
  props: {
    infoData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {},
  //监听执行
  watch: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.myChart = echarts.init(this.$refs.EchartAssetRealtimeStat);
      let option = {
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          type: "category",
          data: ["高高报", "高报", "低报", "正常"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [
              {
                value: this.infoData.highHighReportNow,
                itemStyle: {
                  color: "#FE656A",
                },
              },
              {
                value: this.infoData.highReportNow,
                itemStyle: {
                  color: "#F76E1B",
                },
              },
              {
                value: this.infoData.lowReportNow,
                itemStyle: {
                  color: "#FFC803",
                },
              },
              {
                value: this.infoData.normalNow,
                itemStyle: {
                  color: "#52D297",
                },
              },
            ],
            type: "bar",
            barMaxWidth: 46,
            legendHoverLink: true,
            itemStyle: {
              show: true,
            },
          },
        ],
      };

      this.myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.EchartAssetRealtimeStat {
  width: 100%;
  height: 100%;
  #statusEchart {
    width: 100%;
    height: 100%;
  }
}
</style>
