<!--
 * @Description: 首页
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-04-30 08:56:12
 * @LastEditors: 支磊
 * @LastEditTime: 2022-05-06 16:43:18
-->
<template>
  <div class="index">
    <!--  -->
    <div class="head">
      <div>
        <img class="logo" :src="logo" alt="" />
        <p class="enterpriseName" ref="name">
          {{ enterpriseName }}&nbsp; {{ selectData.name }}
        </p>
      </div>
      <div>
        <el-radio-group size="small" v-model="radioValue" @change="changF">
          <el-radio-button label="3">全部</el-radio-button>
          <el-radio-button label="1">自有</el-radio-button>
          <el-radio-button label="2">关联</el-radio-button>
        </el-radio-group>
        <div
          :class="selectDepartmentS ? 'select on' : 'select off'"
          @click.stop="selectDepartmentS = !selectDepartmentS"
        >
          <el-input
            v-model="fatherDepartment"
            placeholder="请选择设备分组"
            :readonly="true"
            size="small"
          >
            <i
              slot="suffix"
              style="transform-origin: center"
              :style="
                selectDepartmentS
                  ? 'cursor:pointer;padding-right:3px;transform: rotate(180deg)'
                  : 'cursor:pointer;padding-right:3px;transform: rotate(0)'
              "
              class="el-input__icon el-icon-caret-bottom"
            ></i>
          </el-input>
          <transition name="el-fade-in-linear">
            <div class="ops" @click.stop v-show="selectDepartmentS">
              <el-scrollbar style="height: 100%">
                <el-tree
                  auto-expand-parent
                  :data="treedata"
                  :default-expand-all="true"
                  show-checkbox
                  node-key="uId"
                  ref="treedialog"
                  :highlight-current="true"
                  :check-strictly="true"
                  @check="checkChangeF"
                  :props="{ children: 'childGroup', label: 'name' }"
                  :check-on-click-node="true"
                ></el-tree>
              </el-scrollbar>
            </div>
          </transition>
        </div>
      </div>
      <!-- <el-select
        id="indexSelect"
        v-model="value"
        filterable
        placeholder="请选择"
        size="small"
        class="indexSelect"
        ref="select"
        style="width: 320px"
        @change="changeF"
      >
        <el-option
          v-for="item in options"
          :key="item.siteId"
          :label="item.name"
          :value="item.siteId"
        >
        </el-option>
      </el-select> -->
    </div>
    <div class="main">
      <home :infoData="infoData" ref="home" />
    </div>
  </div>
</template>

<script>
import base from "@/config";
import home from "./private/IndexMainCom";
import { site } from "@/api/home.js";
import {
  getEnterpriseName,
  getHoneyDeviceGroup,
  getHoneyWellIndex,
} from "@/api/basics";
export default {
  name: "index",
  components: {
    home,
  },
  data() {
    return {
      value: "",
      options: [],
      selectData: {
        name: "",
      },
      logo: "",
      enterpriseName: "",
      radioValue: "3",
      fatherDepartment: "",
      selectDepartmentS: false,
      treedata: [],
      treeselectData: {},
      infoData: {}, //所有数据
    };
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {
    // this.getSiteF();
    this.getEnterpriseNameF();
    this.getHoneyDeviceGroup(); //获取设备分组
    this.getHoneyWellIndex();
  },
  methods: {
    changF() {
      if (this.radioValue != "0") {
        this.fatherDepartment = ""
        this.$refs.treedialog.setCheckedKeys([],true)
        this.getHoneyWellIndex();
      }
    },
    getHoneyWellIndex(groupId = "") {
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      getHoneyWellIndex({
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        applyId: WORK_APPLY.id, //物联应用id
        type: this.radioValue,
        groupId: groupId,
      }).then((res) => {
        if (this.ApiReply(res)) {
          console.log("数据", res.data);
          this.infoData = res.data.data;
          sessionStorage.setItem("deviceIds", this.infoData.deviceIds);
        }
      });
    },
    checkChangeF(data) {
      // console.log("数据", data);
      this.treeselectData = data;
      this.fatherDepartment = data.name;
      this.radioValue = "0";
      this.$refs.treedialog.setCheckedKeys([data.uId],true)
      this.selectDepartmentS = false
      this.getHoneyWellIndex(data.uId);
    },
    getEnterpriseNameF() {
      //获取企业name，loge
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      getEnterpriseName({
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        applyId: WORK_APPLY.id, //物联应用id
      }).then((res) => {
        if (this.ApiReply(res)) {
          console.log(res.data.data);
          this.logo = "https://www.iqingneng.com" + res.data.data.logo;
          this.enterpriseName = res.data.data.enterpriseName + "   ";
        }
      });
    },
    changeF() {
      let index = 0;
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].siteId == this.value) {
          index = i;
          break;
        }
      }
      this.selectData = {
        ...this.options[index],
      };
      this.value = this.options[index].siteId;
      localStorage.setItem("siteId", this.value);
      this.$refs.home.getDeptSetsF();
    },
    getSiteF() {
      site().then((res) => {
        if (this.apiReply(res)) {
          this.options = [...res.data.data];
          console.log("options", this.options);

          let name = this.options[0].name;
          this.options[0] = {
            name: "所有设备",
            siteId: -1,
          };
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].name.indexOf(name) != -1) {
              this.options[i].name = this.options[i].name.replace(
                name + " ",
                ""
              );
            }
          }
          if (localStorage.getItem("SiteId")) {
            this.value = localStorage.getItem("SiteId");
          } else {
            this.value = -1;
          }
          this.changeF();
        }
      });
    },
    // 获取设备分组
    getHoneyDeviceGroup() {
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      getHoneyDeviceGroup({
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        applyId: WORK_APPLY.id, //物联应用id
      }).then((res) => {
        if (this.ApiReply(res)) {
          this.treedata = res.data.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  min-width: 1340px;
  position: relative;
  background: #f5f6f7;
  height: 100%;
  .head {
    position: absolute;
    top: 0;
    width: 100%;
    height: 60px;
    left: 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid #f5f6f7;
    > div {
      display: flex;
      align-items: center;
      > img {
        width: 40px;
        height: 40px;
      }
      .enterpriseName {
        padding-left: 10px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #131313;
      }
    }
  }
  // 主体
  .main {
    height: 100%;
    padding: 70px 0;
    min-width: 1340px;
    overflow: auto;
  }
  // 弹窗里的样式
  .select {
    margin-left: 15px;
    position: relative;
    width: 260px;
  }
  .ops {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 8px 8px;
    padding-top: 20px;
    transition: all 0.5s;
    position: absolute;
    width: 100%;
    height: 320px;
    // height: 180px;
    overflow: auto;
    top: 32px;
    background: #fff;
  }
}
</style>
<style lang="scss">
.indexSelect {
  input {
    @extend .oneline;
  }
}
</style>
